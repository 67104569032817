import { DateTimeFormat } from '../date/format.constants';

export const RetryAndContactSupportMessage = 'Zkuste to znovu a pokud situace přetrvá, kontaktujte IT podporu.';
export const CommonMessages = {
  NotFound: 'Nic jsme nenašli',
  ChoosePlaceholder: 'Vyberte...',
  ChooseDatePlaceholder: 'Vyberte datum...',
  ChooseDateRangePlaceholder: 'Vyberte datum od - do...',
  CannotLoadData: 'Chyba načítání dat',
  CannotLoadDataRetryAndContactSupport: `Nepovedlo se načíst data.\n\n ${RetryAndContactSupportMessage}`,
  CannotSaveDataRetryAndContactSupport: `Požadavek se nepovedlo uložit.\n\n ${RetryAndContactSupportMessage}`,
  CannotDeleteDataRetryAndContactSupport: `Záznam se nepodařilo smazat.\n\n ${RetryAndContactSupportMessage}`,
  RejectionFailedRetryAndContactSupport: `Požadavek se nepovedlo zamítnout. \n\n ${RetryAndContactSupportMessage}`,
  AbortSendingCommunicationRetryAndContactSupport: `Zrušení odeslání komunikace požadavku se nezdařilo.\n\n ${RetryAndContactSupportMessage}`,
  UnableUploadDocumentsRetryAndContactSupport: `Dokumenty se nepovedlo nahrát.\n\n ${RetryAndContactSupportMessage}`,
  UploadedButUnableCreateRequirement: `Dokumenty se podařilo nahrát, ale nepovedlo se znovu založit požadavek. Zavřete prosím okno a založte požadavek manuálně.`,
  UnableCancelRequirementRetryAndContactSupport: `Požadavek se nepovedlo zrušit. ${RetryAndContactSupportMessage}`,
  UnplannedInvoicingFailedRetryAndContactSupport: `Spuštění mimořádného vyúčtování se nepovedlo. ${RetryAndContactSupportMessage}`,
  CannotLoadUnsupportedImgFormatTryDownload: `Soubor nelze zobrazit.\n Podporované formáty pro zobrazení jsou .jpg, .jpeg, .svg, .png a .pdf.\n Pro zobrazení souboru zkuste soubor stáhnout.`,
  CannotDownloadFileRetryAndContactSupport: `Soubor se nepovedlo stáhnout. ${RetryAndContactSupportMessage}`,
  UnableSendCommunicationRetryAndContactSupport: `Nepovedlo se odeslat komunikaci. ${RetryAndContactSupportMessage}`,
  ForOptionsStartTyping: `Pro zobrazení možností začněte psát`,
  CannotLoadList: `Nepovedlo se načíst seznam`,
  AutomaticAddressActive: `Automatické doplnění adresy je aktivní. \n Po vybrání adresy prosím neupravujte zadané hodnoty v poli "Ulice a číslo". Hodnoty v tomto poli lze nahradit pouze záznamy vybranými z našeptávače.\n
    Pokud hledaná adresa není v našeptávači, přepněte prosím na manuální zadávání adresy.`,
  ManulaAddressActive:
    'Manuální zadávání adresy je aktivní.\n Před uložením prosím překontrolujte údaje v názvu ulice a číslu popisném.',
};

export const ValidationMessages = {
  required: 'Pole nesmí být prázdné',
  fillAllMandatoryFields: 'Nejsou vyplňena všechna povinná pole',
  tooLongValue: 'Zadaná hodnota je příliš dlouhá',
  dateTime: {
    inPast: 'Zadejte datum a čas v budoucnosti',
    inFuture: 'Zadejte datum a čas v minulosti',
    invalid: `Zadejte validní hodnotu ve tvaru ${DateTimeFormat.DateTimeFullHoursDayJS}`,
  },
  date: {
    required: 'Vyberte datum',
    inPast: 'Zadejte datum v budoucnosti',
    inFuture: 'Zadejte datum v minulosti',
    invalid: `Zadejte validní hodnotu ve tvaru ${DateTimeFormat.DateDayJS}`,
  },
  personalIn: {
    invalid: 'Rodné číslo není platné',
  },
  companyIn: {
    invalid: 'IČO není platné',
  },
  phone: {
    invalid: 'Telefon není platný',
  },
  email: {
    invalid: 'E-mail není platný',
  },
  registrationPlate: {
    invalid: 'RZ není platná',
  },
  vin: {
    invalid: 'VIN není platný',
  },
  policyNumber: {
    invalid: 'Číslo smlouvy není platné',
  },
  vtp: {
    invalid: 'VTP není platný',
  },
  orv: {
    invalid: 'ORV není platný',
  },
  ipex: {
    invalid: 'Číslo není platné',
  },
  dropdown: {
    required: 'Vyberte položku',
  },
  bankAccount: {
    invalidFormat: 'Číslo účtu není správné',
    invalidPrefix: 'Prefix není platný',
    invalidBase: 'Číslo účtu není platné',
    invalidBankCode: 'Vložený kód není v seznamu bank',
  },
  iban: {
    invalidFormat: 'IBAN je ve špatném formátu',
    notCZIban: 'Nejedná se o český IBAN',
    invalidIbanCountryCodeFormat: 'IBAN začíná velkými písmeny',
    invalidAccountNumberFormatInIban: 'Číslo účtu v IBAN není ve správném formátu',
    invalidLength: 'Chybná délka',
    invalidCheckDigits: 'Chybná kontrolní číslice',
    invalidAccountNumberPrefixInIban: 'Předčíslí číslo účtu v IBAN je nevalidní',
    invalidAccountNumberBaseInIban: 'Hlavní číslo účtu v IBAN je nevalidní',
    invalidAccountNumberBankCodeInIban: 'Kód banky čísla účtu v IBAN je nevalidní',
  },
  variableSymbol: {
    invalid: 'Variabilní symbol není platný',
  },
};

export const ValidationErrKeys = {
  invalidDate: 'invalidDate',
  inPast: 'inPast',
  inFuture: 'inFuture',
  invalidRegistrationPlate: 'invalidRegistrationPlate',
  required: 'required',
  pattern: 'pattern',
  maxlength: 'maxlength',
  invalidVtp: 'invalidVtp',
  invalidOrv: 'invalidOrv',
  invalidEmail: 'invalidEmail',
};
